// Scripts pour ajouter ou supprimer la classe "no-scroll"
function addNoScroll() {
    if (!document.body.classList.contains("no-scroll")) {
        document.body.classList.add("no-scroll")
    }
}

function removeNoScroll() {
    if (document.body.classList.contains("no-scroll") && !$(th_overlay.selector_overlay_fiche_sit).hasClass('open')) {
        document.body.classList.remove("no-scroll");
    }
}

th_overlay.addCallbackOpen(addNoScroll);
th_overlay.addCallbackClose(removeNoScroll);





// Ajout / suppresion d'une classe quand le mega menu est ouvert
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-menu') {
        $('body').addClass('has-menu-open');
    }

    // Sur mobile, empêche le menu de niveau 2 d'être affiché à l'ouverture par défaut comme sur desktop (attribut checked sur le 1er item)
    if(window.innerWidth < 600) {
        var inputs = document.querySelectorAll('input[name="nav-primary-choices"]:checked');

        for (var i = 0; i != inputs.length; i++) {
            var input = inputs[i];
            $(input).prop('checked',false).removeAttr("checked");           // double suppression attr et prop pour Chrome
        }
    }
})

th_overlay.addCallbackClose(function (overlayId) {
    if (overlayId == 'overlay-menu') {
        $('body').removeClass('has-menu-open');
    }
})


// Ajout / suppresion d'une classe quand l'overlay météo est ouvert
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-meteo') {
        $('body').addClass('has-meteo-open');
    }
})

th_overlay.addCallbackClose(function (overlayId) {
    if (overlayId == 'overlay-meteo') {
        $('body').removeClass('has-meteo-open');
    }
})


// Comportement de toggle open / close sur le bouton de menu
$('.btn-menu').click(function(){
    // Megamenu ouvert -> bouton close du mega-menu
    if($('body').hasClass('has-menu-open')) {
        th_overlay.close('overlay-menu');
        // Overlay météo ouvert -> bouton close de l'overlay météo
    } else if($('body').hasClass('has-meteo-open')) {
        th_overlay.close('overlay-meteo');
        // Aucun overlay d'ouvert -> bouton open du mega-menu
    } else {
        th_overlay.open('overlay-menu', true, true, false);
    }
});

