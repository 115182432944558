(function(){

    $('.slick-carousel').each(function () {

        if ($('> *', this).length > 1) {

            var $slick = $(this);
            var options = {
                dots: true,
                arrows: false,
                loop: false,
                infinite: false,
                variableWidth: false,
                autoplay: false,
                autoplaySpeed: 2000,
            };

            if (this.getAttribute('data-params')) {
                options = eval(this.getAttribute('data-params') + '()');
            }


            var possibleOptions = ["accessibility", "adaptiveHeight", "appendArrows", "appendDots", "arrows", "asNavFor", "autoplay", "autoplaySpeed", "centerMode", "centerPadding", "cssEase", "customPaging", "dots", "dotsClass", "draggable", "easing", "edgeFriction", "fade", "focusOnSelect", "infinite", "initialSlide", "lazyLoad", "mobileFirst", "pauseOnHover", "pauseOnFocus", "pauseOnDotsHover", "respondTo", "responsive", "rows", "rtl", "slide", "slidesPerRow", "slidesToShow", "slidesToScroll", "speed", "swipe", "swipeToSlide", "touchMove", "touchThreshold", "useCSS", "useTransform", "variableWidth", "vertical", "verticalSwiping", "waitForAnimate", "zIndex"];
            var allDatas = $slick.data();

            $.each(allDatas, function (key, val) {
                // Convertit les attributs html récupérés de kebab-case (center-mode) en camel-case (centerMode)
                key = key.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });

                if (possibleOptions.indexOf(key) !== -1) {
                    if (key === 'autoplay') {
                        options.autoplay = true;
                        options.autoplaySpeed = val;
                    } else if (key === 'autoplay') {
                        options.autoplay = true;
                        options.autoplaySpeed = val;
                    } else {
                        options[key] = val;
                    }
                }
            });

            $slick.slick(options);
        }
    });

})();