// Selectric mais un prevent default sur les options, on va donc le desactiver pour le
// sélecteur des catégories dans le blog

// Ce script est nécessaire pour forcer le changement de page au click sur une option
$('#select-categories-competitions').change(function () {
    var val = $(this).val();
    window.location.href = val;
});

$('#select-months-competitions').change(function () {
    var val = $(this).val();
    window.location.href = val;
});


