window.addEventListener("DOMContentLoaded", function (e) {

    // Calcul du décalage vertical entre les items, on remonte en margin-top négatif l'élément suivant
    var items = document.querySelectorAll('.bloc-histoire .item');

    var dateHeight = 0;
    items.forEach(  function (el) {
        el.style.setProperty('--vertical-offset', dateHeight+'px');
        if(el.querySelector('.date'))  dateHeight = el.querySelector('.item-top .date').scrollHeight;
    });
});