if(typeof window.forEach === 'undefined'){
    window.forEach = function (array, callback, scope) {
        for(var i = 0; i < array.length; i++) {
            callback.call(scope, i, array[i]); // passes back stuff we need
        }
    };
}

var thWeather = {

    options: {
        apiUrl: document.location.origin + '/wp-content/themes/starter/ajax/openweathermap.php',
        tagName: 'span',
        matin: '09:00:00',
        aprem: '15:00:00',
    },

    lang: 'fr',

    config: function (options) {

        if (!options) {
            options = {};
        }

        thWeather.options = $.extend({}, thWeather.options, options)

    },


    /**
     * Retourne null ou l'objet openweather de la date demandé complété par degree et iconHtml
     * @param query - {city:city,moment:'matin',day:0,lang:'fr'}
     * @param callbackUser - Fonction passé en "front"
     */
    get: function (query, callbackUser) {
        //console.log("In get");

        if(query.lang){
            thWeather.lang = query.lang;
        }

        thWeather.getCity(query.city, function (cityData) {
            //on récupère la data, cityData étant le "result" passé en paramètre de la fonction "callback" dans "getCity"
            var data = thWeather.getFromCityData(cityData,query.moment,query.day);
            //si y'en as, on génère l'icone a partir de la data et on converti la température en degrés
            if(data){
                data.iconHtml = thWeather._generateIcon(data.weather[0].icon);
                data.degrees = thWeather._convertTemperature(data.main.temp);
                data.temp_max = thWeather._convertTemperature(data.main.temp_max);
                data.temp_min = thWeather._convertTemperature(data.main.temp_min);
                data.winddirection = thWeather._convertWindDirection(data.wind.deg);
            }
            //on revoit la data en paramètre de la fonction de callback déclaré en "front"
            callbackUser(data);

        });
    },


    /**
     * ---------------------------------------------------------------------------
     *      Partie récupération de la donnée
     * ---------------------------------------------------------------------------
     */

    /**
     * Renvoit dans le callback le résultat de la requête Ajax, soit directement depuis le cache, soit grâce à l'Ajax
     * @param cityName - Nom de la ville
     * @param callback
     */

    getCity: function (cityName, callback) {

        // check from local cache (localstorage)
        var result = thWeather.checkStorage(cityName);
        if (!result) { //si rien dans le cache, on lance la requête ajax
            thWeather.ajaxQuery(cityName, function (result) {
                callback(result);
            });
        }else{
            callback(result);
        }
    },

    /**
     * Vérifie que les données d'une ville n'est pas déja présente dans le cache. Si oui, renvoit le JSON décodé avec les valeurs ; sinon renvoit false.
     * @param city - Nom de la ville à checker
     * @returns {*}
     */
    checkStorage: function (city) {
        if (typeof localStorage !== 'undefined') {
            if (localStorage['weather-' + (thWeather._slugify(city)) + thWeather.lang] && localStorage['weather-date-' + (thWeather._slugify(city)) + thWeather.lang] && localStorage['weather-date-' + (thWeather._slugify(city)) + thWeather.lang] == thWeather._getDate()) {
                return JSON.parse(localStorage['weather-' + (thWeather._slugify(city)) + thWeather.lang]);
            }
        }
        return false;
    },

    /**
     * Fonction stockant la data en cache sur le dataStorage (apres avoir stringify les données)
     * @param city - Le nom de la ville concerné
     * @param data - La data associé à la ville
     */
    storeData: function (city, data) {
        if (typeof localStorage !== 'undefined') {
            localStorage['weather-' + (thWeather._slugify(city)) + thWeather.lang] = JSON.stringify(data);
            localStorage['weather-date-' + (thWeather._slugify(city)) + thWeather.lang] = thWeather._getDate();
        }
    },

    /**
     * La requête ajax récupérant les données d'une ville et les stockant dans le cache (dataStorage)
     * @param city - Le nom de la ville
     * @param callback
     */
    ajaxQuery: function (city, callback) {

        // on convertie les espace en + pour être compatible url pour l'api openweathermap
        var cityNameUrl = city.replace(' ', '+');

        $.get(thWeather.options.apiUrl, {city: cityNameUrl,lang:thWeather.lang}, function (result) {
            if (result) {

                thWeather.storeData(city, result);

                if (callback) {
                    callback(result);
                }
            }
        }, 'json');
    },



    /**
     * ---------------------------------------------------------------------------
     *      Traitement de la donnée
     */


    /**
     * Formatage de la data brute récupéré en ajax ou cache en quelquechose d'exploitable
     * @param cityData - La data brute (ajax ou cache)
     * @param timeDemande - Le moment de la journée demandé ("matin","soir","current")
     * @param day - Le jour concerné (aujourd'hui par défaut)
     * @returns {*}
     */
    getFromCityData: function(cityData,timeDemande,day){

        // valeur par défaut
        if(!timeDemande){ timeDemande = 'current'; }
        if(!day){   day = 0;    }


        // si donnée heure courante
        if(timeDemande === 'current'){
            return thWeather.getCurrentFromCityData(cityData);
        }



        // sinon, par rapport a une heure plus précise
        // conversion de day en la bonne date
        var formatedDay = thWeather.__dateFormating(day);

        var dateQueried = formatedDay+' '+thWeather.options[timeDemande];
        //on viens de formater le champ dans me style "2019-03-12 09:00:00" directement utilisable dans le tableau de donnée
        return thWeather.getHourFromCityData(cityData,dateQueried);
    },

    /**
     * Retourne toute les infos d'une ville à l'heure actuelle
     * @param cityData - La data brute d'une ville particulière
     * @returns {*}
     */
    getCurrentFromCityData: function(cityData){

        var selected = null;
        var currentDT = new Date().getTime()/1000;

        if (cityData.list){
            forEach(cityData.list,function(k,val){
                if(selected === null && val.dt > currentDT){
                    selected = val;
                }
            });
        }

        return selected;


    },


    /**
     * Retourne toute les infos d'une ville à un moment donné
     * @param cityData - La data brute d'une ville particulière
     * @param date - L'heure / momment dont on veut avoir les infos
     * @returns {*}
     */
    getHourFromCityData: function(cityData,date){
        var selected = null;
        if (cityData.list){
            forEach(cityData.list,function(k,val){
                if(val.dt_txt == date){
                    selected = val;
                }
            });
        }



        return selected;
    },





    /**
     *  ---------------------------------------------------------------------------
     *  Partie Tools
     */

    __dateFormating: function (day){
        if(typeof day == 'number'){
            // cas ou c'est le nombre de jour en plus d'aujourd'hui (int)
            var formated = '';
            var date = new Date( (new Date().getTime() + ( day * 86400000) ) );

            formated += date.getFullYear();

            var month = (date.getMonth()+1);
            if(month < 10){ month = '0'+month }
            formated += '-'+month;

            var day = date.getDate();
            if(day < 10){ day = '0'+day }
            formated += '-'+day;

            return formated;
        }else{
            // cas date texte libre
            return day;
        }
    },

    _slugify: function(city){
        city = city.toString().toLowerCase().trim()
            .replace(/&/g, '-')
            .replace(/[\s\W-]+/g, '-');//
        return city;
    },

    _getDate: function(){
        var date = new Date();
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        return year+'/'+monthIndex+'/'+day;
    },



    _convertTemperature: function(kelvin){
        // Convert the temperature to either Celsius or Fahrenheit:
        return Math.round('c' == 'c' ? (kelvin - 273.15) : (kelvin*9/5 - 459.67));
    },

    _convertWindDirection: function (deg) {
        var dataWindDirections = {
            1:{
                direction: 'N',
                min: 348.75,
                max: 11.25
            },
            2:{
                direction: 'NNE',
                min: 11.25,
                max: 33.75
            },
            3:{
                direction: 'NE',
                min: 33.75,
                max: 56.25
            },
            4:{
                direction: 'ENE',
                min: 56.25,
                max: 78.75
            },
            5:{
                direction: 'E',
                min: 78.75,
                max: 101.25
            },
            6:{
                direction: 'ESE',
                min: 101.25,
                max: 123.75
            },
            7:{
                direction: 'SE',
                min: 123.75,
                max: 146.25
            },
            8:{
                direction: 'SSE',
                min: 146.25,
                max: 168.75
            },
            9:{
                direction: 'S',
                min: 168.75,
                max: 191.25
            },
            10:{
                direction: 'SSW',
                min: 191.25,
                max: 213.75
            },
            11:{
                direction: 'SW',
                min: 213.75,
                max: 236.25
            },
            12:{
                direction: 'WSW',
                min: 236.25,
                max: 258.75
            },
            13:{
                direction: 'W',
                min: 258.75,
                max: 281.25
            },
            14:{
                direction: 'WNW',
                min: 281.25,
                max: 303.75
            },
            15:{
                direction: 'NW',
                min: 303.75,
                max: 326.25
            },
            16:{
                direction: 'NNW',
                min: 326.25,
                max: 348.75
            },
        };

        for (key in dataWindDirections) {
            min = dataWindDirections[key].min;
            max = dataWindDirections[key].max;
            direction = dataWindDirections[key].direction;
            keep_this = false;

            if(key == 1 ) {
                if(deg >= min || deg <= max) {
                    deg_to_keep = 0;
                    keep_this = true;
                }
            } else if(deg >= min && deg <= max) {
                deg_to_keep = parseInt((max - min) / 2 + min);
                keep_this = true;
            }

            if(keep_this) {
                return {direction: direction,deg: deg_to_keep};
            }

        }
    },

    _generateIcon: function(numero){

        var night = false;
        var nightOrDay = numero[2].toString();
        if(nightOrDay === 'n'){  night = true; }

        var num = numero[0].toString()+numero[1].toString();

        var html = '';



        var sunny = 'sunny';
        var sun = 'sun';
        if(night){
            sunny = 'night';
            sun = 'moon';
        }

        switch(num){
            case '01':
                html += '<'+thWeather.options.tagName+' class="meteo-'+sun+'"></'+thWeather.options.tagName+'>';
                break;
            case '02':
                html += '<'+thWeather.options.tagName+' class="meteo-cloud"></'+thWeather.options.tagName+'>';
                html += '<'+thWeather.options.tagName+' class="meteo-'+sunny+'"></'+thWeather.options.tagName+'>';
                break;
            case '03':
                html += '<'+thWeather.options.tagName+' class="meteo-cloud"></'+thWeather.options.tagName+'>';
                break;
            case '04':
                html += '<'+thWeather.options.tagName+' class="meteo-cloud"></'+thWeather.options.tagName+'>';
                break;
            case '09':
                html += '<'+thWeather.options.tagName+' class="meteo-basecloud"></'+thWeather.options.tagName+'>';
                html += '<'+thWeather.options.tagName+' class="meteo-drizzle"></'+thWeather.options.tagName+'>';
                break;
            case '10':
                html += '<'+thWeather.options.tagName+' class="meteo-basecloud"></'+thWeather.options.tagName+'>';
                html += '<'+thWeather.options.tagName+' class="meteo-drizzle"></'+thWeather.options.tagName+'>';
                html += '<'+thWeather.options.tagName+' class="meteo-'+sunny+'"></'+thWeather.options.tagName+'>';
                break;
            case '11':
                html += '<'+thWeather.options.tagName+' class="meteo-basecloud"></'+thWeather.options.tagName+'>';
                html += '<'+thWeather.options.tagName+' class="meteo-thunder"></'+thWeather.options.tagName+'>';
                break;
            case '13':
                html += '<'+thWeather.options.tagName+' class="meteo-basecloud"></'+thWeather.options.tagName+'>';
                html += '<'+thWeather.options.tagName+' class="meteo-frosty"></'+thWeather.options.tagName+'>';
                break;
            case '50':
                html += '<'+thWeather.options.tagName+' class="meteo-mist"></'+thWeather.options.tagName+'>';
                break;
        }

        return html;
    }
};


/**

 exemple

 thWeather.config({
    apiUrl:document.location.origin + '/wp-content/themes/beaufortain/ajax/openweathermap.php',
    tagName:'li',
    soir: '21:00:00',
    nuit: '03:00:00',
});




 $('[data-weather]').each(function(){

    var _self = this;

    var city = $(this).data('weather');

    var list = ['nuit','matin','aprem','soir'];

    $.each(list,function(k,moment){
        thWeather.get({city:city,moment:moment},function(data){
            if(data){
                console.log(data);
                $('.temp-'+moment,_self).html(data.degrees);
            }
        });
    });
});
 */
