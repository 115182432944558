

/* Initialisation du slider */
(function () {

    var blocs = document.querySelectorAll('.bloc-parcours');

    for (var i=0; i != blocs.length; i++) {
        var containerTabs = document.querySelector('.tabs-parcours');
        initTabs(containerTabs);
    }

    function initTabs(container) {
        var tabs = container.querySelectorAll('label');

        for (var i = 0; i != tabs.length; i++) {
            var tab = tabs[i];
            tab.addEventListener('click', onClickTab);
        }
    }


    function onClickTab(e) {
        e.preventDefault();
        var target = e.currentTarget;

        // empèche le scroll en haut de page au click sur le label
        var radioId = target.getAttribute('for');
        var radio = document.querySelector('#' + radioId);
        radio.click();

        var bloc = target.closest('.bloc-parcours');
        bloc.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }


})();